let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.filter_open = function filter_open() {
    document.getElementById('filter_open').classList.toggle('show_filter');
    document.getElementById('search_open').classList.toggle('hidden_search');
    document.getElementById('filter_close').classList.toggle('la-times');
}
window.filter_namjena =  function filter_namjena() {
    document.getElementById('filter_namjena').classList.toggle('show_list');
}
window.filter_turizam =  function filter_turizam() {
    document.getElementById('filter_turizam').classList.toggle('show_list');
}
window.filter_vrsta =  function filter_vrsta() {
    document.getElementById('filter_vrsta').classList.toggle('show_list');
}
window.filter_lokacija =  function filter_lokacija() {
    document.getElementById('filter_lokacija').classList.toggle('show_list');
}
window.filter_list =  function filter_list() {
    document.getElementById('filter_list').classList.toggle('show_filter_list');
    document.getElementById('filter_open').classList.remove('show_filter');    
    document.getElementById('filter_close').classList.toggle('la-times');
}
window.filter_list_close =  function filter_list_close() {
    document.getElementById('filter_list').classList.toggle('show_filter_list');
}

window.settings_open = function settings_open() {
    document.getElementById('settings_open').classList.toggle('show_settings');    
    document.getElementById('search_open').classList.toggle('hidden_search');
    document.getElementById('settings_close').classList.toggle('la-times');
}
window.layers_open = function layers_open() {
    document.getElementById('layers_open').classList.toggle('show_layers');
    document.getElementById('layers_close').classList.toggle('la-times');
}
window.info_open_all = function info_open_all() {
    document.getElementById('info_open_all_btn').classList.add('hidden_btn');
    document.getElementById('info_close_all_btn').classList.remove('hidden_btn');    
    document.getElementById('infoBoxTable').classList.remove('hidden_part');
    document.getElementById('info_prostorniPlan').classList.add('show_list');
    document.getElementById('info_utjecajNaOkolis').classList.add('show_list');
    document.getElementById('info_vlasnistvo').classList.add('show_list');
    document.getElementById('info_granicaPomorskogDobra').classList.add('show_list');
    document.getElementById('info_koncesijaPomorskogDobra').classList.add('show_list');
    document.getElementById('info_posebniUvjeti').classList.add('show_list');
    document.getElementById('info_idejniProjekt').classList.add('show_list');
    document.getElementById('info_lokacijskaDozvola').classList.add('show_list');
    document.getElementById('info_pravomocnost').classList.add('show_list');
    document.getElementById('info_glavniProjekt').classList.add('show_list');
    document.getElementById('info_gradevinskaDozvola').classList.add('show_list');
    document.getElementById('info_pravomocnostGradevinskeDozvole').classList.add('show_list');
    document.getElementById('info_prijavaGradnje').classList.add('show_list');
    document.getElementById('info_tehnickiPregled').classList.add('show_list');
    document.getElementById('info_uporabnaDozvola').classList.add('show_list');
    document.getElementById('info_kategorizacija').classList.add('show_list');
    document.getElementById('info_evidentiranje').classList.add('show_list');
}
window.info_close_all = function info_open_all() {    
    document.getElementById('info_open_all_btn').classList.remove('hidden_btn');
    document.getElementById('info_close_all_btn').classList.add('hidden_btn');
    document.getElementById('infoBoxTable').classList.add('hidden_part');
    document.getElementById('info_prostorniPlan').classList.remove('show_list');
    document.getElementById('info_utjecajNaOkolis').classList.remove('show_list');
    document.getElementById('info_vlasnistvo').classList.remove('show_list');
    document.getElementById('info_granicaPomorskogDobra').classList.remove('show_list');
    document.getElementById('info_koncesijaPomorskogDobra').classList.remove('show_list');
    document.getElementById('info_posebniUvjeti').classList.remove('show_list');
    document.getElementById('info_idejniProjekt').classList.remove('show_list');
    document.getElementById('info_lokacijskaDozvola').classList.remove('show_list');
    document.getElementById('info_pravomocnost').classList.remove('show_list');
    document.getElementById('info_glavniProjekt').classList.remove('show_list');
    document.getElementById('info_gradevinskaDozvola').classList.remove('show_list');
    document.getElementById('info_pravomocnostGradevinskeDozvole').classList.remove('show_list');
    document.getElementById('info_prijavaGradnje').classList.remove('show_list');
    document.getElementById('info_tehnickiPregled').classList.remove('show_list');
    document.getElementById('info_uporabnaDozvola').classList.remove('show_list');
    document.getElementById('info_kategorizacija').classList.remove('show_list');
    document.getElementById('info_evidentiranje').classList.remove('show_list');
}
window.info_prostorniPlan =  function info_prostorniPlan() {
    document.getElementById('info_prostorniPlan').classList.toggle('show_list');
}
window.info_utjecajNaOkolis =  function info_utjecajNaOkolis() {
    document.getElementById('info_utjecajNaOkolis').classList.toggle('show_list');
}
window.info_vlasnistvo =  function info_vlasnistvo() {
    document.getElementById('info_vlasnistvo').classList.toggle('show_list');
}
window.info_granicaPomorskogDobra =  function info_granicaPomorskogDobra() {
    document.getElementById('info_granicaPomorskogDobra').classList.toggle('show_list');
}
window.info_koncesijaPomorskogDobra =  function info_koncesijaPomorskogDobra() {
    document.getElementById('info_koncesijaPomorskogDobra').classList.toggle('show_list');
}
window.info_posebniUvjeti =  function info_posebniUvjeti() {
    document.getElementById('info_posebniUvjeti').classList.toggle('show_list');
}
window.info_idejniProjekt =  function info_idejniProjekt() {
    document.getElementById('info_idejniProjekt').classList.toggle('show_list');
}
window.info_lokacijskaDozvola =  function info_lokacijskaDozvola() {
    document.getElementById('info_lokacijskaDozvola').classList.toggle('show_list');
}
window.info_pravomocnost =  function info_pravomocnost() {
    document.getElementById('info_pravomocnost').classList.toggle('show_list');
}
window.info_glavniProjekt =  function info_glavniProjekt() {
    document.getElementById('info_glavniProjekt').classList.toggle('show_list');
}
window.info_gradevinskaDozvola =  function info_gradevinskaDozvola() {
    document.getElementById('info_gradevinskaDozvola').classList.toggle('show_list');
}
window.info_pravomocnostGradevinskeDozvole =  function info_pravomocnostGradevinskeDozvole() {
    document.getElementById('info_pravomocnostGradevinskeDozvole').classList.toggle('show_list');
}
window.info_prijavaGradnje =  function info_prijavaGradnje() {
    document.getElementById('info_prijavaGradnje').classList.toggle('show_list');
}
window.info_tehnickiPregled =  function info_tehnickiPregled() {
    document.getElementById('info_tehnickiPregled').classList.toggle('show_list');
}
window.info_uporabnaDozvola =  function info_uporabnaDozvola() {
    document.getElementById('info_uporabnaDozvola').classList.toggle('show_list');
}
window.info_kategorizacija =  function info_kategorizacija() {
    document.getElementById('info_kategorizacija').classList.toggle('show_list');
}
window.info_evidentiranje =  function info_evidentiranje() {
    document.getElementById('info_evidentiranje').classList.toggle('show_list');
}

window.smallMap_open = function smallMap_open() {
    document.getElementById('smallMap_open').classList.toggle('show_smallMap');
    document.getElementById('smallMap_close').classList.toggle('la-times');
}

window.open_general =  function open_general() {
    document.getElementById('infoBoxTable').classList.toggle('hidden_part');
    document.getElementById('general_close').classList.toggle('la-angle-down');
}